@import "./../../scss/abstracts/variables";

.tabs {
  .MuiTabs-flexContainer {
    border-bottom: 1px solid $geyser;
    padding-left: 2rem;
  }

  .MuiTab-root {
    min-width: 13.5rem;
  }

  .MuiTab-root {
    text-transform: initial;
  }

  .MuiTab-root {
    min-height: auto;
    &.Mui-selected {
      background: $danube;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      .MuiTab-wrapper {
        > *:first-child {
          color: white;
        }
      }
    }
    .MuiTab-wrapper {
      flex-direction: row;
      > *:first-child {
        width: 2rem;
        color: $success;
        margin-right: 1rem !important;
      }
    }
  }
}
