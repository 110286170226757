@import './../../scss/abstracts/variables';

.status-card {
    border-radius: 0.4rem;
    border-left: 0.8rem solid;
    background: $white;
    cursor: pointer;
    position: relative;

    .card-body {
        margin: 0 0 0.8rem 0;
        color: $heather;
        min-height: 2rem;
    }

    .card-footer {
        margin: 0.8rem 0 0 0;
    }

    .tp-position {
        position: absolute;
        bottom: 4px;
        right: 8px;
        cursor: pointer;
        color: $primary-matisse;
    }
}