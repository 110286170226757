.patient-details {
  form {
    width: 100%;
  }
  .entity-details {
    .edit-button {
      margin-right: -1rem;
    }
  }

  .MuiAccordion-root.Mui-expanded {
    margin-top: 0;
  }

  .processing-fee-total {
    margin-top: -1rem;
  }

  + footer {
    padding: 2.5rem;
    border-top: 3px solid rgba(0, 0, 0, 0.15);
  }
}

