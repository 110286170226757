@import "./../../scss/abstracts/variables";

.buttongroup {
  border: 2px solid $heather;
  display: inline-block;

  label {
    display: inline-block;
    padding: 0.6rem 1rem;
    cursor: pointer;
    color: $heather;
    background-color: white;
    font-weight: 600;
    transition: all 0.2s;

    &:hover {
      background-color: $sail;
    }
  }
  input {
    display: none;
    &:checked + label {
      background-color: $sail;
      color: $primary-matisse;
    }
    &:disabled + label {
      pointer-events: none;
    }
  }

  &.disabled {
    border-color: $heather;
    pointer-events: none;

    label {
      color: $disabled;
      background-color: $disabled;
    }
    input {
      &:checked + label {
        background-color: $disabled;
        color: $disabled;
      }
    }
  }
}
