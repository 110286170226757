@import "./../../scss/abstracts/variables";

.rules-modal{
  &.buttongroup-2 {
    min-height: 4rem;
    border: 2px solid $heather;

    .MuiGrid-item {
      label {
        padding: 0.6rem 1rem;
        cursor: pointer;
        color: $heather;
        background-color: white;
        font-weight: 600;
        transition: all 0.2s;

        &:hover {
          background-color: $sail;
        }
      }
      input {
        display: none;
        &:checked + label {
          background-color: $sail;
          color: $primary-matisse;
        }
        &:disabled + label {
          pointer-events: none;
        }
      }
    }

    &.disabled {
      border-color: $heather;
      pointer-events: none;
      background-color:$disabled;

      .MuiGrid-item{
        label {
          display: none;
        }
        input {
          display: none;
        }
      }
    }
  }


}