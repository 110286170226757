@import "./../../../scss/abstracts/variables";

.admin-roles-edit {
    .MuiPaper-root {
        padding: 1.5rem;
    }

    .role-header {
        padding-bottom: .5rem;
    }

    .MuiIconButton-root.menu {
        margin-left: 'auto';
    }

    .card-header {
        background: $mystic;
        margin: 0.5rem 0;

        div {
            padding: .5rem;
        }
    }

    .card-header-actions {
        background: #F5F7F9;
    }

    .data-container {
        padding-left: 1rem;
        
        &:nth-child(odd) {
            background-color: $haze;
        }
    }

    .data-list {
        list-style:none;
    }

    .data-item {
        padding-left: 1rem;
    }

    .MuiPopover-paper {
        margin-top: 2rem;
    }
}
