@import "./../../../scss/abstracts/variables";

.facilities-view {
  .inline {
    color: $goldenrod;
    max-height: 0.6em;
    text-align: left;
    float: left;
    margin-top: 0.2em;
  }
}