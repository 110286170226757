
.container-row {
  margin-bottom: 20px;
}

.table-row {
  margin: 1rem 0;
  width: 100%;
}

.table-cell-other{
  width: 30%;
}

.table-cell-inn{
  width: 10%
}

.table-header-items{
  width: 300px;
}
