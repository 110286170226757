@import "../../../scss/abstracts/variables";

.active-view {
  .missed-payments{
    border:  2px solid $warning;
  }
  .missed-payments-span {
    background-color: $warning;
    color:white;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;

  svg{
      margin-right: 10px;
    }
  }

  .view-content {
    min-width: 420px;
  }
}

