header {
  display: flex;
  align-items: center;
  background: $secondary-stone;
  padding: 1.5rem;

  .logo {
    display: flex;

    img {
      height: 2.5rem;
    }
  }

  .header-nav {
    a {
      color: white;
      padding-left: 2.5rem;
    }
    .menu-item {
      color: #297ec1;
    }
  }
}
