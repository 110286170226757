@import "./../../../scss/abstracts/variables";

.payment-channel-fees-table {
  table-layout: fixed;
  width: 100%;
  .table-area {
    padding: 0 0 22px 0;
    display: flex;
    align-items: center;
    h3 {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.6rem;
      padding-right: 16px;
    }
    .header-sub-label {
      font-size: 12px;
      line-height: 12px;
    }
    .input-holder {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }
  }
  .left-pad {
    padding-left: 5px;
  }
  .payment-channel-fees-table {
    margin-top: 10px;
    border-collapse: collapse; 
    table-layout: fixed;
  }
  .field-container {
    align-items: center;
    input {
      flex: 1;
    }
  }
  .cell-container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .fee-subhead {
    text-align: center;
    line-height: 16px;
    font-size: 14px;
    padding: 0px 0px 10px 0px;
  }
  .fee-mainhead {
    text-align: center;
    font-weight: bold;
    line-height: 18px;
    padding: 8px 0 6px 0;
    font-size: 15px;
    &:first-child {
      padding: 8px 0 11px 0;
    }
  }
  .truncate-label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 18ch; 
  }
  .hero-fees{
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
}
.input-checkbox-loc {
  .checkbox .label {
    text-align: left;
  }
}
