@import "./../../../scss/abstracts/variables";

.admin-roles {
    .dialog {
        max-width: 800px;
    }

    .MuiDialog-paperWidthSm {
        max-width: 800px;
    }

    .MuiPaper-root {
        padding: 1.5rem;
    }

    .role-header {
        padding-bottom: .5rem;
    }

    .MuiIconButton-root.menu {
        margin-left: 'auto';
    }

    .card-header {
        background: $mystic;

        div {
            padding: .5rem;
        }
    }

    .data-container {
        margin: 1.5rem 0;
    }

    .data-list {
        list-style:none;
    }

    .data-item {
        padding-left: 1rem;
    }

    .MuiPopover-paper {
        margin-top: 2rem;
    }

    .external-role-list {
        margin-top: 1rem;
    }

    .table.alternating-row {
        .MuiTableCell-head {
            padding: 0.5rem 1rem;
            width: 33.3%;
        }

        .MuiTableCell-root {
            vertical-align: top;
            width: 33.3%;
        }

        .MuiTableCell-body {
            padding: 1rem 1rem;
        }
    }
}

.role-modal {
   .MuiDialog-paperWidthMd {
        max-width: 685px;
      }
  }
  
