$ratio0_5: 0.2rem;
$ratio1: 0.4rem;
$ratio2: 0.8rem;
$ratio3: 1.2rem;
$ratio4: 1.6rem;
$ratio5: 2rem;
$ratio6: 2.4rem;
$ratio7: 2.8rem;
$ratio8: 3.2rem;

/*
m = margin
p = padding
t = top
b = bottom
l = left
r = right
x = left and right (x-axis)
y = top and bottom (y-axis)
*/

/* Margins */
.m-0,
[class^="Mui"].m-0 {
  margin: 0;
}
.mt-0,
[class^="Mui"].mt-0 {
  margin-top: 0;
}
.mb-0,
[class^="Mui"].mb-0 {
  margin-bottom: 0;
}
.ml-0,
[class^="Mui"].ml-0 {
  margin-left: 0;
}
.mr-0,
[class^="Mui"].mr-0 {
  margin-right: 0;
}
.mx-0,
[class^="Mui"].mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.my-0,
[class^="Mui"].my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-0_5,
[class^="Mui"].m-0_5 {
  margin: $ratio0_5;
}
.mt-0_5,
[class^="Mui"].mt-0_5 {
  margin-top: $ratio0_5;
}
.mb-0_5,
[class^="Mui"].mb-0_5 {
  margin-bottom: $ratio0_5;
}
.ml-0_5,
[class^="Mui"].ml-0_5 {
  margin-left: $ratio0_5;
}
.mr-0_5,
[class^="Mui"].mr-0_5 {
  margin-right: $ratio0_5;
}
.mx-0_5,
[class^="Mui"].mx-0_5 {
  margin-left: $ratio0_5;
  margin-right: $ratio0_5;
}
.my-0_5,
[class^="Mui"].my-0_5 {
  margin-top: $ratio0_5;
  margin-bottom:$ratio0_5;
}

.word-wrap{
  word-wrap: break-word;
}

.break-word{
  word-break: break-all;
}

.m-1,
[class^="Mui"].m-1 {
  margin: $ratio1;
}
.mt-1,
[class^="Mui"].mt-1 {
  margin-top: $ratio1;
}
.mb-1,
[class^="Mui"].mb-1 {
  margin-bottom: $ratio1;
}
.ml-1,
[class^="Mui"].ml-1 {
  margin-left: $ratio1;
}
.mr-1,
[class^="Mui"].mr-1 {
  margin-right: $ratio1;
}
.mx-1,
[class^="Mui"].mx-1 {
  margin-left: $ratio1;
  margin-right: $ratio1;
}
.my-1,
[class^="Mui"].my-1 {
  margin-top: $ratio1;
  margin-bottom: $ratio1;
}

.m-2,
[class^="Mui"].m-2 {
  margin: $ratio2;
}
.mt-2,
[class^="Mui"].mt-2 {
  margin-top: $ratio2;
}
.mb-2,
[class^="Mui"].mb-2 {
  margin-bottom: $ratio2;
}
.ml-2,
[class^="Mui"].ml-2 {
  margin-left: $ratio2;
}
.mr-2,
[class^="Mui"].mr-2 {
  margin-right: $ratio2;
}
.mx-2,
[class^="Mui"].mx-2 {
  margin-left: $ratio2;
  margin-right: $ratio2;
}
.my-2,
[class^="Mui"].my-2 {
  margin-top: $ratio2;
  margin-bottom: $ratio2;
}

.m-3,
[class^="Mui"].m-3 {
  margin: $ratio3;
}
.mt-3,
[class^="Mui"].mt-3 {
  margin-top: $ratio3;
}
.mb-3,
[class^="Mui"].mb-3 {
  margin-bottom: $ratio3;
}
.ml-3,
[class^="Mui"].ml-3 {
  margin-left: $ratio3;
}
.mr-3,
[class^="Mui"].mr-3 {
  margin-right: $ratio3;
}
.mx-3,
[class^="Mui"].mx-3 {
  margin-left: $ratio3;
  margin-right: $ratio3;
}
.my-3,
[class^="Mui"].my-3 {
  margin-top: $ratio3;
  margin-bottom: $ratio3;
}

.m-4,
[class^="Mui"].m-4 {
  margin: $ratio4;
}
.mt-4,
[class^="Mui"].mt-4 {
  margin-top: $ratio4;
}
.mb-4,
[class^="Mui"].mb-4 {
  margin-bottom: $ratio4;
}
.ml-4,
[class^="Mui"].ml-4 {
  margin-left: $ratio4;
}
.mr-4,
[class^="Mui"].mr-4 {
  margin-right: $ratio4;
}
.mx-4,
[class^="Mui"].mx-4 {
  margin-left: $ratio4;
  margin-right: $ratio4;
}
.my-4,
[class^="Mui"].my-4 {
  margin-top: $ratio4;
  margin-bottom: $ratio4;
}

.m-5,
[class^="Mui"].m-5 {
  margin: $ratio5;
}
.mt-5,
[class^="Mui"].mt-5 {
  margin-top: $ratio5;
}
.mb-5,
[class^="Mui"].mb-5 {
  margin-bottom: $ratio5;
}
.ml-5,
[class^="Mui"].ml-5 {
  margin-left: $ratio5;
}
.mr-5,
[class^="Mui"].mr-5 {
  margin-right: $ratio5;
}
.mx-5,
[class^="Mui"].mx-5 {
  margin-left: $ratio5;
  margin-right: $ratio5;
}
.my-5,
[class^="Mui"].my-5 {
  margin-top: $ratio5;
  margin-bottom: $ratio5;
}

.m-6,
[class^="Mui"].m-6 {
  margin: $ratio6;
}
.mt-6,
[class^="Mui"].mt-6 {
  margin-top: $ratio6;
}
.mb-6,
[class^="Mui"].mb-6 {
  margin-bottom: $ratio6;
}
.ml-6,
[class^="Mui"].ml-6 {
  margin-left: $ratio6;
}
.mr-6,
[class^="Mui"].mr-6 {
  margin-right: $ratio6;
}
.mx-6,
[class^="Mui"].mx-6 {
  margin-left: $ratio6;
  margin-right: $ratio6;
}
.my-6,
[class^="Mui"].my-6 {
  margin-top: $ratio6;
  margin-bottom: $ratio6;
}

.m-7,
[class^="Mui"].m-7 {
  margin: $ratio7;
}
.mt-7,
[class^="Mui"].mt-7 {
  margin-top: $ratio7;
}
.mb-7,
[class^="Mui"].mb-7 {
  margin-bottom: $ratio7;
}
.ml-7,
[class^="Mui"].ml-7 {
  margin-left: $ratio7;
}
.mr-7,
[class^="Mui"].mr-7 {
  margin-right: $ratio7;
}
.mx-6,
[class^="Mui"].mx-6 {
  margin-left: $ratio6;
  margin-right: $ratio6;
}
.my-6,
[class^="Mui"].my-6 {
  margin-top: $ratio6;
  margin-bottom: $ratio6;
}

.m-8,
[class^="Mui"].m-8 {
  margin: $ratio8;
}
.mt-8,
[class^="Mui"].mt-8 {
  margin-top: $ratio8;
}
.mb-8,
[class^="Mui"].mb-8 {
  margin-bottom: $ratio8;
}
.ml-8,
[class^="Mui"].ml-8 {
  margin-left: $ratio8;
}
.mr-8,
[class^="Mui"].mr-8 {
  margin-right: $ratio8;
}
.mx-8,
[class^="Mui"].mx-8 {
  margin-left: $ratio8;
  margin-right: $ratio8;
}
.my-8,
[class^="Mui"].my-8 {
  margin-top: $ratio8;
  margin-bottom: $ratio8;
}

/* Padding */
.p-0,
[class^="Mui"].p-0 {
  padding: 0;
}
.pt-0,
[class^="Mui"].pt-0 {
  padding-top: 0;
}
.pb-0,
[class^="Mui"].pb-0 {
  padding-bottom: 0;
}
.pl-0,
[class^="Mui"].pl-0 {
  padding-left: 0;
}
.pr-0,
[class^="Mui"].pr-0 {
  padding-right: 0;
}
.px-0,
[class^="Mui"].px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-0,
[class^="Mui"].py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-0_5,
[class^="Mui"].p-0_5 {
  padding: $ratio0_5;
}
.pt-0_5,
[class^="Mui"].pt-0_5 {
  padding-top: $ratio0_5;
}
.pb-0_5,
[class^="Mui"].pb-0_5 {
  padding-bottom: $ratio0_5;
}
.pl-0_5,
[class^="Mui"].pl-0_5 {
  padding-left: $ratio0_5;
}
.pr-0_5,
[class^="Mui"].pr-0_5 {
  padding-right: $ratio0_5;
}
.px-0_5,
[class^="Mui"].px-0_5 {
  padding-left: $ratio0_5;
  padding-right: $ratio0_5;
}
.py-0_5,
[class^="Mui"].py-0_5 {
  padding-top: $ratio0_5;
  padding-bottom: $ratio0_5;
}

.p-1,
[class^="Mui"].p-1 {
  padding: $ratio1;
}
.pt-1,
[class^="Mui"].pt-1 {
  padding-top: $ratio1;
}
.pb-1,
[class^="Mui"].pb-1 {
  padding-bottom: $ratio1;
}
.pl-1,
[class^="Mui"].pl-1 {
  padding-left: $ratio1;
}
.pr-1,
[class^="Mui"].pr-1 {
  padding-right: $ratio1;
}
.px-1,
[class^="Mui"].px-1 {
  padding-left: $ratio1;
  padding-right: $ratio1;
}
.py-1,
[class^="Mui"].py-1 {
  padding-top: $ratio1;
  padding-bottom: $ratio1;
}

.p-2,
[class^="Mui"].p-2 {
  padding: $ratio2;
}
.pt-2,
[class^="Mui"].pt-2 {
  padding-top: $ratio2;
}
.pb-2,
[class^="Mui"].pb-2 {
  padding-bottom: $ratio2;
}
.pl-2,
[class^="Mui"].pl-2 {
  padding-left: $ratio2;
}
.pr-2,
[class^="Mui"].pr-2 {
  padding-right: $ratio2;
}
.px-2,
[class^="Mui"].px-2 {
  padding-left: $ratio2;
  padding-right: $ratio2;
}
.py-2,
[class^="Mui"].py-2 {
  padding-top: $ratio2;
  padding-bottom: $ratio2;
}

.p-3,
[class^="Mui"].p-3 {
  padding: $ratio3;
}
.pt-3,
[class^="Mui"].pt-3 {
  padding-top: $ratio3;
}
.pb-3,
[class^="Mui"].pb-3 {
  padding-bottom: $ratio3;
}
.pl-3,
[class^="Mui"].pl-3 {
  padding-left: $ratio3;
}
.pr-3,
[class^="Mui"].pr-3 {
  padding-right: $ratio3;
}
.px-3,
[class^="Mui"].px-3 {
  padding-left: $ratio3;
  padding-right: $ratio3;
}
.py-3,
[class^="Mui"].py-3 {
  padding-top: $ratio3;
  padding-bottom: $ratio3;
}

.p-4,
[class^="Mui"].p-4 {
  padding: $ratio4;
}
.pt-4,
[class^="Mui"].pt-4 {
  padding-top: $ratio4;
}
.pb-4,
[class^="Mui"].pb-4 {
  padding-bottom: $ratio4;
}
.pl-4,
[class^="Mui"].pl-4 {
  padding-left: $ratio4;
}
.pr-4,
[class^="Mui"].pr-4 {
  padding-right: $ratio4;
}
.px-4,
[class^="Mui"].px-4 {
  padding-left: $ratio4;
  padding-right: $ratio4;
}
.py-4,
[class^="Mui"].py-4 {
  padding-top: $ratio4;
  padding-bottom: $ratio4;
}

.p-5,
[class^="Mui"].p-5 {
  padding: $ratio5;
}
.pt-5,
[class^="Mui"].pt-5 {
  padding-top: $ratio5;
}
.pb-5,
[class^="Mui"].pb-5 {
  padding-bottom: $ratio5;
}
.pl-5,
[class^="Mui"].pl-5 {
  padding-left: $ratio5;
}
.pr-5,
[class^="Mui"].pr-5 {
  padding-right: $ratio5;
}
.px-5,
[class^="Mui"].px-5 {
  padding-left: $ratio5;
  padding-right: $ratio5;
}
.py-5,
[class^="Mui"].py-5 {
  padding-top: $ratio5;
  padding-bottom: $ratio5;
}

.p-6,
[class^="Mui"].p-6 {
  padding: $ratio6;
}
.pt-6,
[class^="Mui"].pt-6 {
  padding-top: $ratio6;
}
.pb-6,
[class^="Mui"].pb-6 {
  padding-bottom: $ratio6;
}
.pl-6,
[class^="Mui"].pl-6 {
  padding-left: $ratio6;
}
.pr-6,
[class^="Mui"].pr-6 {
  padding-right: $ratio6;
}
.px-6,
[class^="Mui"].px-6 {
  padding-left: $ratio6;
  padding-right: $ratio6;
}
.py-6,
[class^="Mui"].py-6 {
  padding-top: $ratio6;
  padding-bottom: $ratio6;
}

.p-7,
[class^="Mui"].p-7 {
  padding: $ratio7;
}
.pt-7,
[class^="Mui"].pt-7 {
  padding-top: $ratio7;
}
.pb-7,
[class^="Mui"].pb-7 {
  padding-bottom: $ratio7;
}
.pl-7,
[class^="Mui"].pl-7 {
  padding-left: $ratio7;
}
.pr-7,
[class^="Mui"].pr-7 {
  padding-right: $ratio7;
}
.px-7,
[class^="Mui"].px-7 {
  padding-left: $ratio7;
  padding-right: $ratio7;
}
.py-7,
[class^="Mui"].py-7 {
  padding-top: $ratio7;
  padding-bottom: $ratio7;
}

.p-8,
[class^="Mui"].p-8 {
  padding: $ratio8;
}
.pt-8,
[class^="Mui"].pt-8 {
  padding-top: $ratio8;
}
.pb-8,
[class^="Mui"].pb-8 {
  padding-bottom: $ratio8;
}
.pl-8,
[class^="Mui"].pl-8 {
  padding-left: $ratio8;
}
.pr-8,
[class^="Mui"].pr-8 {
  padding-right: $ratio8;
}
.px-8,
[class^="Mui"].px-8 {
  padding-left: $ratio8;
  padding-right: $ratio8;
}
.py-8,
[class^="Mui"].py-8 {
  padding-top: $ratio8;
  padding-bottom: $ratio8;
}

.center-y {
  display: flex;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.hide {
  display: none;
}

.hidden {
  visibility: hidden;
  height: 0;
}

.breaklongstring {
  overflow-wrap: anywhere; // no IE11 or Safari support
}

.link {
  cursor: pointer;
  color: $primary-matisse;
    &.light {
      color: $white;
    }
  }

.positive-number {
  color: $success;
}

.negative-number {
  color: $error;
}