
.client-facility-checkbox{
  .clients {
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .checkbox-item{
    margin-left: 0.5rem;
  }
}