@import "./../../../scss/abstracts/variables";

.rules-engine {
  .incomplete-icon {
    color: $heather;
  }

  .complete-icon {
    color: $success;
  }

  .checkbox-group {
    color: $secondary-stone;
    font-size: 14px;
  }

  .settings-input {
    padding-bottom: 1em;
    max-width: 19.2em;
  }

  .btn-align {
    float: right;
  }

  .primary-color {
    color: $primary-matisse;
  }
}