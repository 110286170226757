@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400;1,600&display=swap');
@import "./../../scss/abstracts/variables";

html {
  font-size: 62.5%; // 1rem = 10px;
}

body,
.body {
  font-family: $primary;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-small {
  font-family: $primary;
  font-size: 1.4rem;
  line-height: 2;
  color: $secondary-stone;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary;
  color: $black;
  font-weight: 600;
}

h1,
.title {
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 600;
}

h2,
.subtitle1 {
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

h3,
.subtitle2 {
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: $secondary-stone;
  margin-bottom: 1rem;
}

h4,
.small-title {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  color: $secondary-stone;
}

a {
  text-decoration: none;
  color: $primary-matisse;
}

address {
  font-style: normal;
}

.bulleted-list {
  li:first-child {
    margin-top: 1rem;
  }

  li {
    margin-left: 4rem;
    margin-bottom: 1rem;
  }
}

::-webkit-input-placeholder { /* Edge */
  color: $dark-gray !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $dark-gray !important;
}

::placeholder {
  color: $dark-gray !important;
}


