@import './../../scss/abstracts/variables';

.list-header {
  .header {
    background-color: $mystic;
  }

  .list-item-shade {
    background-color: $haze;
  }
}