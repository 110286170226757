@import "./../../scss/abstracts/variables";

.checkbox,
.radio {
  cursor: pointer;
  display: flex;
  align-items: center;

  input {
    display: none;
    &:not(:checked) ~ span.checkbox-on,
    &:not(:checked) ~ span.radio-on {
      display: none;
    }
    &:checked ~ span.checkbox-off,
    &:checked ~ span.radio-off {
      display: none;
    }
  }

  .label {
    margin-left: 1.5rem;
    &.disabled {
      color: $heather;
      cursor: not-allowed;
    }
  }

  .checkbox-on,
  .checkbox-off,
  .radio-on,
  .radio-off {
    color: $primary-matisse;
    &.disabled {
      color: $heather;
      cursor: not-allowed;
    }
  }
}

// Text fields, dropdowns, auto complete

.MuiTextField-root {
  .MuiInputLabel-root {
    &.Mui-focused {
      color: $primary-matisse;
    }
    &.Mui-disabled {
      background: white;
      padding: 0 2px;
      color: rgb(107, 107, 107);
    }
  }

  .MuiInputBase-root {
    background: white;

    .MuiSelect-selectMenu {
      padding: 1.3rem;
    }

    .MuiInputAdornment-root {
      color: $heather;
      > p {
        font-size: 2rem;
      }
    }

    .MuiInputBase-input {
      height: 0.5em;
    }

    /* Hide up/down number spinners in all browsers */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    &.Mui-focused .MuiInputAdornment-root {
      color: $primary-matisse;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 3px solid $heather;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 3px solid $primary-matisse;
    }

    &.Mui-focused .MuiSelect-select {
      background: white;
    }

    &.Mui-disabled {
      ::placeholder {
        color: $secondary-stone;
      }
      .MuiOutlinedInput-notchedOutline {
        background: rgba(31, 36, 75, 0.2);
        border-color: $heather;
      }
      .MuiInputAdornment-root {
        display: none;
      }
    }
  }

  .placeholder .MuiSelect-select {
    color: #C9CDD2;
    font-size: 1.4rem;
    padding: 1.4rem;
  }

  ::placeholder {
    font-size: 1.4rem;
  }

  .MuiFormHelperText-root {
    font-size: 1.4rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }

  .MuiAutocomplete-popupIndicator {
    display: none;
  }

  + .error-message {
    color: $error;
    font-size: 1.4rem;
    margin-top: 1rem;
    flex-wrap: nowrap;

    .MuiSvgIcon-root {
      font-size: 2.2rem;
      margin-right: 1rem;
    }
  }

  .text-field {
    input:-webkit-autofill {
      -webkit-text-fill-color: black;
      -webkit-box-shadow: 0 0 0 100px white inset;
              box-shadow: 0 0 0 100px white inset;
    }
  }
}
