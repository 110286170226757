@import '../abstracts/variables';

.icon-colors {
  .icon {
    color: $primary-matisse;
  }

  .gray-icon {
    color: $heather;
  }

  .green-icon {
    color: $success;
  }

  .red-icon {
    color: $error;
  }

  .inline-icon {
    text-align: left;
    float: left;
    margin-top: 0.5em;
    font-size: 0.7em;
  }
}