@import "./../abstracts/variables";

.hamburger-menu { 
  button {
    padding: 0;
  } 
  span {
    color: white;
  }
}
.mobile-payment-header {
    background: $white;
    .logo {
        margin: 13px 0 0 0px;
        img {
          height: 2.4em;
        }
    }
    .hamburger-menu {
        padding-bottom: 14px;
        border-bottom: 1px solid #BEBEBE;
        margin: 0 18px;
    }
}
nav.mobile-sub-navigation {
  align-items: center;
  background: white;
  border-bottom: 3px solid rgba(0, 0, 0, 0.15);
  &:last-child {
    border: 0;
  }
  .content {
    text-align: center;
    ul.menu {
      list-style: none;
    }
    li.menu-item {
      a {
        display: inline-block;
        padding: 2.3rem 1rem;
        color: $dark-gray;
        &.active {
          border-bottom: 3px solid $primary-matisse;
          color: black;
        }
      }
    }
  }
}
