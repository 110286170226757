@import "./../abstracts/variables";

footer {
    display: flex;    
    padding: 2rem;
    justify-content: center;
    background: #f5f7f9;    

    &.no-padding {
      padding: 0;
    }

    .content {
      @media only screen and (min-width: 960px) {
        width: 70%;
      }
      &.narrow {
        width: 50%;
      }

      &.fullwidth {
        width: 100%;
      }

      a {
        display: inline-block;
        padding-right: 2.3rem;
     
        &.active {
          box-shadow: 0px -3px 0px inset $primary-matisse;
         
        }
      }
    }
}