@import "./../../scss/abstracts/variables";

.MuiTableHead-root .MuiTableCell-root {
  background: $mystic;
}
.table.alternating-row {
  .MuiTableRow-root:nth-child(even) {
    background: $haze;
  }
  .MuiTableCell-body {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}
.light-green-row {
  background: $lightgreenrow
}
.light-blue-row {
  background: $lightbluerow
}

table.normal-pad td {
  padding: 1em !important;
} 