@import "./../../../scss/abstracts/variables";

// Includes styles for main Implementation Specialist Clients page view
// plus the detail view with the 4 tabs (Entity Details, Entity Settings, Facilities, Rules Engine)


// implementation specialist Clients page with filter checkboxes and status cards
.implementation-clients {
  form {
    width: 100%;
  }
  .entity-details {
    .edit-button {
      margin-right: -1rem;
    }
  }

  .MuiAccordion-root.Mui-expanded {
    margin-top: 0;
  }

  .processing-fee-total {
    margin-top: -1rem;
  }



  + footer {
    padding: 2.5rem;
    border-top: 3px solid rgba(0, 0, 0, 0.15);
  }
}


  .client-filters {
    min-width: 25rem;
    position: relative;
    .MuiAccordion-root {
      background: transparent;
      margin: 0;
      &.Mui-expanded {
        margin: 0;
      }

      .MuiAccordionSummary-root {
        padding: 0;

        .MuiAccordionSummary-content {
          margin-bottom: 0;
        }
      }
      .MuiCollapse-container {
        .MuiAccordionDetails-root {
          padding-left: 0;
          padding-top: 0;
        }
      }
    }
  }

