@import "./../../scss/abstracts/variables";

// overflow three-dot menu popup menu
.MuiMenu-paper {
  margin-top: 4rem;
  text-align: center;

  .primary {
    color: $primary-matisse;
  }

  .danger {
    color: $error;
  }
}

.striped-row-container {
  >:nth-child(even) {
    background: $haze;
    margin-bottom: 15px;
  }
  >:nth-child(odd) {
    margin-bottom: 15px;
    background: #ffffff;
  }
  > div {
    padding-bottom: 1.5rem;
    .MuiTypography-root {
      margin-bottom: 0;
    }
  }
}

.striped-row-riskassess-summary {
  >:nth-child(even) {
    background: $haze;
  }
  >:nth-child(odd) {
    background: #ffffff;
  }
}

.MuiPopover-paper {
  margin-top: 2rem;
}
