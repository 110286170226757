@import "./../abstracts/variables";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  main {
    display: flex;
    height: 100%;
    padding: 2rem;
    justify-content: center;
    background: #f5f7f9;
    overflow: auto;

    &.no-padding {
      padding: 0;
    }

    .content {
      @media only screen and (min-width: 960px) {
        width: 95%;
      }
      &.narrow {
        width: 50%;
      }

      &.fullwidth {
        width: 100%;
      }
      &.extra-padding {
        padding-bottom: 5em;
      }
    }
  }
}
.state-field {
  .MuiInputBase-root .MuiInputBase-input {
    min-height: 13px;
    padding-top: 10px !important;
    padding-bottom: 18px !important;
  }
}
.tab-align {
  display: flex;
  justify-content: end;
  padding-bottom: 9px;
}
.platform-footer {
  padding-top: 6px;
  cursor: pointer;
}
.inline-modal-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(256, 256, 256, 0.6);
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 2;
}
.nowrap {
  white-space: nowrap;
}
.active-icon {
  color: $success;
  font-size: 18px;
  padding-right: 4px;
}
.inactive-icon {
  color: $dark-gray;
  font-size: 18px;
  padding-right: 4px;
}
.checkbox-wlabel {
  display: flex;
  align-items: center;
  .sub-label {
    font-size: 12px;
    color: $dark-gray
  }
}
.overlay-load {
  position: absolute;
  top: 1px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 256, 0.85);
  z-index: 2;
  margin-left: -31px;
  .mobile-loader {
    background-color: transparent !important;
  }
}
.field-container-100 {
  .button {
    width: 100%;
  }
}
.field-container {
  .button {
    width: 74%;
  }
}
.fees-table {
  .field-container .button {
    width: 100%;
  }
  .MuiTextField-root .MuiInputBase-root {
    padding-left: 8px;
    padding-right: 9px;
  }
}
.access-error {
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .message {
    font-size: 1.2em;
    color: $error;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .action {
    color: $primary-matisse;
    font-size: 1em;
    margin-top: 14px;
    cursor: pointer;
  }
}
.error-border {
  .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: $error !important;
  }
}
.label-chbx .MuiFormControlLabel-label {
  flex: 1
}

// vob modal adjustment for vob modal from shared components
.modal.width-adjust .MuiDialog-paperWidthMd {
  max-width: 72rem !important;
}