@import "./../../../scss/abstracts/variables";

.admin-users {
  .MuiPaper-root {
    padding: 1.5rem;
  }
  // .MuiTableCell-stickyHeader {
  //   background: $mystic;
  // }
  // table {
  //   .MuiTableRow-root:nth-child(even) {
  //     background: $haze;
  //   }
  //   .MuiTableCell-body {
  //     padding-top: 0rem;
  //     padding-bottom: 0rem;
  //   }
  // }
}
