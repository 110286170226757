@import './../../scss/abstracts/variables';

.section-complete-status {
    .icon {
        margin-right: 1rem;

        &.complete {
            color: $success;
        }
        &.incomplete {
            color: $primary-matisse;
        }
    }
}