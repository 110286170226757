@import "./../../scss/abstracts/variables";

.loginflow {
  .MuiPaper-root {
    margin-top: 8rem;
    padding: 3rem;
    max-width: 50rem;
  }

  .terms-conditions {
    margin-top: 4rem;
  }

  .login_actions {
    padding: 10rem;
  }
}

.login-container {
  width: 100vw;
  overflow: hidden;

  .forgot-password {
    color: $secondary-stone;
  }
}
