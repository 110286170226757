@import "./../../scss/abstracts/variables";

.mobile-page-container {
    .mobile-heading {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        font-family: 'Raleway', sans-serif;
        color: $mobileblue;
        text-transform: capitalize;
        padding-left: 4px;
        margin-bottom: 18px;
    }
    .mobile-subhead {
        font-size: 16px;
        line-height: 23px;
        color: $secondary-stone;
        font-weight: 500;
        padding-bottom: 40px;
    }
    .mobile-header {
        font-family: 'Raleway', sans-serif;
        color: $raven;
        font-size: 23px;
        position: absolute;
        top: 29px;
        right: 40px;
    }
    .mobile-pay-amount {
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 20px;
        padding-left: 11px;
        .amount-label {
            color: $black;
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            line-height: 1.4375em;
            font-weight: 400;
            letter-spacing: 0.00938em;
            padding-bottom: 4px;
        }
    }
    .input-item {
        margin-bottom: 16px;
        padding-top: inherit;
    }
    .input-payment-item {
        padding-top: 1em;
    }
    .MuiPaper-root {
        .MuiPaper-elevation {
            .MuiPaper-rounded .MuiPaper-elevation1 {
                box-shadow: none !important;
            }
        }
    }
    .state-field {
        .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
            padding: 9.5px 14px;
        }
    }
    .MuiTextField-root {
        svg {
            color: #286ba4 !important;
        }
        .MuiInputBase-root {
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid $mobileblue;
            }
        }
    }
    .button-holder {
        text-align: left;
        .button Button.primary-btn {
            background: $mobileblue;
            width: 100%;
        }
        .button Button.primary-btn:disabled {
            opacity: .5;
            pointer-events: none;
        }        
    }
    .form-updates {
        box-shadow: none;
    }
}
.mobile-loader {
    height: 100vh;
    left: 0;
    position: absolute;
    top: 1px;
    z-index: 999999;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.85);
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20%;
    .text-warn {
        font-family: 'Raleway', sans-serif;
        color: $primary-matisse;
        font-weight: 700;
        font-size: 20px;
        width: 79%;
        text-align: center;
        display: flex;
        flex-direction: column;
        line-height: 26px;
        min-width: 240px;
    }
    .time-warn {
        font-size: 18px;
        padding-top: 12px;
        color: #1d1d1d;
        font-weight: 500;
        line-height: 23px;
    }
}
.payment-wrapper {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 0.5em;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
    box-shadow: none;
}
.mobile-modal {
    .spa-content p {
        font-size: 14px;
        padding-bottom: 12px;
    }
    .spa-modal-title {
        font-family: 'Raleway', sans-serif;
        color: $primary-matisse;
    }
    &.modal .MuiDialog-paperWidthMd {
        height: 90vh;
        min-width: 300px;
    }
}
.requirements-modal {
    .spa-content p {
        font-size: 14px;
        padding-bottom: 12px;
    }
    .spa-modal-title {
        font-family: 'Raleway', sans-serif;
        color: $primary-matisse;
    }
    &.modal .MuiDialog-paperWidthMd {
        height: auto;
        max-width: 525px;
    }
}

@media (min-width: 900px) {
    .mobile-header {
        padding-bottom: 12px !important;
        position: relative !important;
        top: initial !important;
        right: initial !important;
    }
}
@media (max-width: 768px) {
    .mobile-page-container {
        .mobile-header {
            width: 48%;
            top: 19px;
            text-align: right;
            font-size: 20px;
        }
    }
    .mobile-modal {
        &.modal .MuiDialog-paperWidthMd {
            max-width: 93%;
            margin: 32px 0;
        }
        .spa-modal-title {
            line-height: 32px;
            padding-top: 24px;
            font-size: 21px !important;
            white-space: nowrap;
        }
        .spa-content p {
            font-size: 13px;
            line-height: 20px;
        }
    }
}
@media (max-width: 440px) {
    .payment-wrapper {
        padding-left: 25px;
        padding-right: 15px;
    }
    .mobile-page-container {
        min-width: 300px;
        .mobile-header {
            width: 48%;
            top: 14px;
            right: 19px;
            text-align: right;
            font-size: 18px;
        }
        .mobile-payment-header {
            .hamburger-menu {
                margin: 0 0px;
                padding-left: 0px;
                padding-right: 0px;
            }
            .logo img {
                height: 2.1em;
                margin-top: 0px;
            }
        }
    }
}

@media (max-width: 330px) {
    .mobile-header {
        display: none;
    }
}

.table-header {
  background-color: $mystic;
}
