.dispute-doc {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .dispute-header {
      text-align: center;

      h1 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
      }

      h3 {
          font-size: 16px;
          margin: 5px 0;
      }
  }

  .dispute-mini-header {
      font-size: 20px;
      font-weight: bold;
      margin: 20px 0 10px;
      text-align: left;
      border-bottom: 2px solid #ccc;
      padding-bottom: 5px;
  }

  .dispute-box {
      display: flex;
      align-items: flex-start;
      gap: 15px;
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;
      background-color: #fff;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

      &-icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #e9ecef; // Light gray background for icons
          border-radius: 5px;
          img {
              width: 20px;
              height: 20px;
          }
      }

      &-content {
          flex: 1;

          h2 {
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 8px;
          }

          h3 {
              font-size: 14px;
              margin: 4px 0;
              color: #555;
          }
      }
  }
}
