.hrSeperator{
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: black !important;
    height: 2px;
}

.darkSectionBackground {
    background-color: #CFECFF;
}

.lightSectionBackground {
    background-color: #E8F6FF;
    border: 0px solid black;
}

.noSectionBackground {
    border: 0px solid black; 
}

.propertyName {
    font-weight: bold;
}

