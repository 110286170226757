@import  '../../scss/abstracts/variables';

.stripe {
  .stripe-card-field {
    max-height: 4rem;
    border-bottom: .2rem solid $heather;
    &:hover {
      border-bottom: .2rem solid $black;
    }
  }
}
