@import "./../../scss/abstracts/variables";

.modal {
  .MuiDialogTitle-root {
    padding: 3rem 3rem 1rem 3rem;
    font-size: 5rem;
  }

  .MuiTypography-root.MuiTypography-h6 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .MuiTypography-subtitle1 {
    font-size: 2rem;
    font-weight: 600;
  }

  .MuiDialogActions-root {
    padding: 3rem;
    border-top: 1px solid $sail;
    margin-top: 2rem;
  }

  .MuiDialogContent-root {
    padding: 0.8rem 3rem;
  }

  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 2.5rem;
  }

  .MuiDialog-paperWidthMd {
    max-width: 70rem;
  }

  &.delete-modal, &.lock-modal {
    .MuiDialog-paper {
      border-top: 10px solid;
    }

    .MuiDialog-paperWidthMd {
      max-width: 50rem;
    }

    .MuiSvgIcon-root {
      font-size: 4rem;
    }
  }

  &.delete-modal {
    .MuiDialog-paper {
      border-top-color: $error;
    }
  }

  &.lock-modal {
    &.info {
      .MuiDialog-paper {
        border-top-color: $primary-matisse;
      }

      .MuiSvgIcon-root {
        color: $primary-matisse;
      }
    }
    &.error {
      .MuiDialog-paper {
        border-top-color: $error;
      }
      .MuiSvgIcon-root {
        color: $error;
      }
    }
    &.warning {
      .MuiDialog-paper {
        border-top-color: $warning;
      }
      .MuiSvgIcon-root {
        color: $warning;
      }
    }
  }

  &.notify-modal {
    .MuiDialog-paper {
      border-top: 10px solid $primary-matisse;
    }

    .MuiDialog-paperWidthMd {
      max-width: 50rem;
    }

    .MuiSvgIcon-root {
      font-size: 4rem;
    }
  }
}
