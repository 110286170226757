@import './../../scss/abstracts/variables';

.facility-list-item {
  .btn-container {
    float: right;
  }

  .text-style {
    color: $secondary-stone;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
  }

  .icon-label-inline {
    display: inline-block;
    padding-right: 1.4rem;
  }
}