/* colors */
$primary-matisse: #297ec1;
$secondary-stone: #505f79;
$lochmara: #0093d5;
$chathams: #27aee3;
$danube: #9bd4f5;
$sail: #e5eff6;
$raven: #00508e;
$heather: #afbecd;
$geyser: #ccd4db;
$mystic: #e1e7ec;
$disabled: #d2d3db;
$black: #1d1d1d;
$white: #ffffff;
$dark-gray: #7f8994;
$dark-gray-th: #989EA5;
$light-gray-th: #E2E7EA;
$haze: #f5f7f9;
$goldenrod: #ffa700;
$info: #7356cc;
$error: #df1e44;
$error-pale: #ffe7ea;
$success: #38b079;
$warning: #f2db00;
$lightgreenrow: #e1f8ea;
$lightbluerow: #d3f4fc;
$mobileblue: #3882cb;

/* fonts */
$primary: Inter, "Helvetica Neue", sans-serif;
