
.account-holder-email-verification{
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   .font-color{
     color: #808080;
   }
  .icon-size{
    font-size: 30rem;
  }

 }






