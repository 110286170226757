@import "./../../../scss/abstracts/variables";

// .admin-clients {
// }

.client-modal {
  .MuiDialog-paperWidthMd {
    max-width: 685px;
  }
}

.date-optional {
  font-size: 1.4rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-right: 14px;
  margin-bottom: 0;
}